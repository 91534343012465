@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$aa: #f5f5f7;
$ab: #f8f8ff;
$ac: #f7f7f7;
$ad: #153359;

body {
  font-size: 16px;
  font-family: "Kanit", sans-serif;
  background: #fff;
  margin: 0;
  padding: 0;
}

.app {
  .container {



    // How to
    .howto {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: $ad;
      padding: 10px;
      margin: 10px;
      gap: 5px;
      border-style: dashed;
      border-radius: 25px;


      img {
        width: 90%;
      }
    }

    // Contact Us
    #contactus {
      display: flex;
      margin: 10px;
      border-radius: 25px;
      justify-content: center;
      align-items: center;
      border-style: dashed;

      .about {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
    // Single inside home

    #-single {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $ad;
      margin: 10px;
      border-radius: 25px;
      padding: 10px;
      border-style: dashed;

      .btn-back {
        width: 100px;
        margin-top: 20px;
        background-color: $ad;
        color: #fff;
        border-radius: 25px;
      }

      img {
        border-radius: 25px;
        padding: 1px;
      }

      .user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 5px;

        .info {
          display: flex;
          align-items: center;
          justify-content: center;

          .edit {
            display: flex;
            gap: 3px;
            img {
              display: flex;
              align-items: center;
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .detail {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin: 5px;
          .item {
            display: flex;
            border-style: dashed;
            padding: 5px;
            justify-content: start;
            align-items: center;
            border-radius: 25px;
            cursor: copy;

            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }

      .img-show {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
        gap: 1px;
      }

      .comments-section {
        display: flex;
        flex-direction: column;

        h4 {
          background-color: $ad;
          color: #fff;
          border: none;
          border-radius: 25px;
          align-items: center;
          text-align: center;
        }
      }

      .add-comment {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 5px;

          textarea {
            width: 100%;
            border-radius: 5px;
          }

          button {
            margin: 10px;
            padding: 10px;
            width: 150px;
            height: 40px;
            background-color: $ad;
            color: #fff;
            border: none;
            border-radius: 25px;
          }
      }

    }

    // Write Sydney

    #-sydney-write {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      margin: 10px;
      padding: 10px;
      border-radius: 25px;
      border-style: dashed;
      border-color: $ad;

      .content {
        gap: 1px;
        top: 50px;
        .contacts {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px;
          gap: 3px;
          input {
            width: 120px;
            color: $ad;
          }
        }
      }

      .-item {
        display: flex;
        flex-direction: column;
        text-align: start;
        color: $ad;

        .btn-back {
          width: 100px;
          margin-top: 20px;
          background-color: $ad;
          color: #fff;
          border-radius: 25px;
        }
      }

      .cat {
        display: flex;
        .listmenu{
          display: flex;
          align-items: center;
          text-align: center;
          height: 35px;
          width: 350px;
        }
      }

      p {
        font-size: 11px;
      }

      .btn-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        button {
          background-color: $ad;
          color: #fff;
          font-size: 20px;
          margin: 20px;
          height: 100px;
          width: 200px;
          border-radius: 25px;
          border: none;
          border-bottom: 2px solid black;
        }
      }

      .menu {
        justify-content: center;
        align-items: center;
        padding: 25px;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: start;
          gap: 7px;
          padding: 25px;
          color: $ad;
        }
      }

      .phone {
        display: flex;
        align-items: center;
        padding: 15px;
        gap: 4px;
        justify-content: center;

        input {
          text-align: center;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        color: $ad;

        input {
          width: auto;
          height: 30px;
          font-size: 16px;
        }
        .editor {
          display: flex;
          height: 250px;
          width: 350px;
          overflow-x: scroll;
          flex-direction: column;
          background-color: #fff;
          color: $ad;
        }
        .editor-title {
          display: flex;
          height: 150px;
          flex-direction: column;
          background-color: #fff;
          color: $ad;
        }
      }
    }

    // Sydney page

    #-sydney {
      display: grid;
      justify-content: center;
      align-items: center;

      grid-template:
        "w w"
        "t t"
        "ad ad"
        "s s"
        "p p"
        "btn btn";

        .search {
          display: flex;
          justify-content: center;
          align-items: center;
          grid-area: s;
          gap: 5px;
          background-color: $ac;
          padding: 5px;
          color: $ad;

          input {
            width: 300px;
            height: 30px;
            border-radius: 5px;
            border: none;
          }

          button {
            font-size: 14px;
            width: 70px;
            height: 30px;
            border-radius: 25px;
            background-color: $ad;
            color: #fff;
            border: none;
            cursor: pointer;
          }
        }

      .write {
        grid-area: w;
        display: flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 0;
        background-color: #fff;
        padding: 10px;
        z-index: 1;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $ad;
          text-decoration: none;
        }
      }

      .slider {
        grid-area: ad;
        width: auto;
        position: relative;
        overflow: hidden;
        margin: 5px;
        padding: 1px;
        border-radius: 25px;

        .image {
          display: flex;
          border-radius: 25px;
          width: 100%;
          height: auto;
        }

        .arrow {
          border: 2px solid white;
          background-color: transparent;
          color: #fff;
          cursor: pointer;
          height: auto;
          width: auto;
          border-radius: 50%;
          position: absolute;
          z-index: 2;
        }
        .arrow:hover {
          background-color: #fff;
          color: #777;
        }

        .next {
          top: 50%;
          right: 1rem;
        }
        .prev {
          top: 50%;
          left: 1rem;
        }
      }

      // .ads {
      //   grid-area: ad;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   margin: 10px;
      //   padding: 3px;

      //   img {
      //     display: flex;
      //     border-radius: 25px;
      //     width: 100%;
      //   }
      // }

      .load-more {
        grid-area: btn;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn-load {
          display: flex;
          margin: 10px;
          justify-content: center;
          align-items: center;
          background-color: $ad;
          color: #fff;
          padding: 10px;
          padding-left: 20px;
          padding-right: 20px;
          border-radius: 25px;
          border: none;
        }
      }

      .-tags {
        grid-area: t;
        display: flex;
        gap: 5px;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
        padding: 10px;

        .tags {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $ad;
          height: 25px;
          width: 100px;
          border-radius: 25px;

          a {
            color: #fff;
            text-decoration: none;
          }
        }

        .tags:hover {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          height: 25px;
          width: 100px;
          border-radius: 25px;
          border-style: dashed;

          a {
            color: $ad;
            text-decoration: none;
          }
        }
      }

      .posts {
        grid-area: p;
        padding: 5px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 20px;

        .post {
          display: flex;
          flex-direction: column;
          background-color: $ac;
          width: 350px;
          height: auto;
          padding: 10px;
          border-radius: 15px;
          border-style: dashed;
          border-color: $ad;
          border-width: thin;
          // border-bottom: 1px solid black;

          .top {
            display: flex;
            justify-content: space-between;
            position: relative;
            .cat-post {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: $ad;
              color: #fff;
              width: 130px;
              border-radius: 25px;
            }
  
            .view {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 2px;
              color: $ad;
              border-radius: 25px;
            }
          }

          .button-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 2px;
            padding: 1px;
            position: relative;
            width: 100%;
            color: $ad;

            button {
              background-color: #fff;
              color: $ad;
              border-width: thin;
              border-radius: 25px;
              position: end;
            }
          }
        }



        .content {
          padding: 3px;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          overflow: auto;
          -webkit-box-orient: vertical;
          background-color: #fff;
          border-radius: 15px;
          // line-height: 0.4;
          color: #153359;
        }
      }
    }

    // Login page

    #-login {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #fff;

      span {
        align-items: center;
        display: flex;
        padding: 5px;
      }

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px;
        padding: 20px;
        border-radius: 25px;
        background-color: #fff;
        border-style: dashed;
        border-color: $ad;
        gap: 10px;
        color: $ad;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          font-weight: 500;
          background-color: $ad;
          color: #fff;
          padding: 10px;
          border-radius: 25px;
        }

        img {
          width: 350px;
        }

        input {
          width: 200px;
          height: 30px;
          border: none;
          border-bottom: 1px dashed $ad;
          background-color: #fff;
        }
      }
    }

    // Main City Town
#main-page {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      background-color: #fff;

      .exchange-rates h4{
        font-size: 13px;
        color: $ad;
      }

  .text-news {
        display: flex;
        margin: 5px;
        border-radius: 25px;
        width: 100%;
        background-color: #f7f7f7;
        color: $ad;
  }

  .city-town {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 25px;
        margin: 10px;
        border-radius: 25px;
        gap: 5px;

        .-city-town a {
          text-decoration: none;
      }
        .-city-town {
          display: flex;
          flex-direction: column;
          border-radius: 25px;
          background-color: $ad;
          position: relative;

          .-temperature {
            display: flex;
            flex-direction: row;
            justify-content: start;
            position: absolute;
            align-items: center;
            text-align: start;
            top: 0;
            left: 15px;
            text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); /* เงาสีดำโปร่งใส */

            .-img {
              position: absolute;
              top: 0;
              right: 0px;
            }
          }

      }
      Link {
        text-decoration: none;
      }

      img {
          border-radius: 25px 25px 0 0;
          width: 350px;
          height: 300px;
      }

      p {
        color: #fff;
      }
  }




}

    // #main-page {
    //   display: flex;
    //   justify-content: center;
    //   flex-flow: row wrap;
    //   gap: 10px;
    //   padding: 20px;
    //   background-color: #fff;

    //   .text-news {
    //     display: flex;
    //     margin: 5px;
    //     border-radius: 25px;
    //     width: 100%;
    //     background-color: #f7f7f7;
    //     color: $ad;
    //   }

    //   .city-town {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     text-align: center;
    //     background-color: $ad;
    //     border-radius: 25px;

    //     a {
    //       text-decoration: none;
    //       color: #fff;
    //     }

    //     p {
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       gap: 5px;
    //     }

    //     img {
    //       border-radius: 25px 25px 0 0;
    //       width: 350px;
    //       height: 300px;
    //     }
    //   }
    // }

    // Footer

    #footer {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: $ad;
      color: #fff;

      img {
        width: 250px;
      }
    }

    // Navbar Menubar Login Logout

    #-iconbar {
      background-color: $ad;

      padding: 15px;

      @media only screen and (max-width: 900px) {
        .nav-icon {
          display: none !important;
        }
      }

      @media only screen and (min-width: 900px) {
        .-ham {
          display: none !important;
        }
      }

      // Burger icon
      .nav-menu {
        background-color: #fff;
        width: 50%;
        height: 100vh;
        display: flex;
        justify-content: center;
        position: fixed;
        top: 0;
        right: -100%;
        transition: 850ms;
        z-index: 3;
      }

      .nav-menu.active {
        right: 0px;
        transition: 350ms;
      }

      .nav-text {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 8px 0px 8px 16px;
        list-style: none;
        height: 40px;
      }

      .nav-text a {
        text-decoration: none;
        color: $ad;
        font-size: 16px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding:0 16px;
        border-radius: 25px;
        border: none;
        border-bottom: 2px solid $ad;
      }

      .nav-text a:hover {
        background-color: $ad;
        color: #fff;
      }

      .nav-menu-items {
        width: 100%;
      }

      .navbar-toggle {
        background-color: none;
        display: flex;
        justify-content: center;
        padding: 25px;
        top: 250px;
      }

      .menu-bars {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $ad;
        color: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
      }

      .menu-bars:hover {
        background-color: #fff;
        color: $ad;
      }

      span {
        margin-left: 16px;
      }

      .-ham {
        display: flex;
        justify-content: center;
        height: 30px;
        width: 30px;
      }

      .-ham:hover {
        display: flex;
      }

      // Menu list

      .icon-menu {
        display: flex;
        background-color: none;
        justify-content: space-between;
        color: #fff;

        .logo-icon {
          img {
            width: 240px;
          }
        }

        .nav-icon {
          display: flex;
          align-items: center;
          text-decoration: none;
          gap: 10px;

          .user-id {
            display: flex;
            gap: 5px;
            cursor: pointer;
          }

          a {
            text-decoration: none;
            color: #fff;
          }

          a:hover {
            font-size: large;
          }
        }
      }
    }
  }
}
